import { HttpService } from '@/services/HttpService';
class Entrepreneurship {
  subdomain = '/enterprice';

  /**
   * Get academy detail data
   * data: empty Object
   * @returns {Promise}
   */
  getAcademyDetail(id) {
    return HttpService.get(`${this.subdomain}/academy/detail?Id=${id}`);
  }
  /**
   * Get quiz data
   * @param {number} {Required} contentTypeId
   * @param {number}  priorty //if the request was made for the first question, the priorty parameter should not be added
   * @returns {Promise}
   */
  getQuizData(data) {
    return HttpService.post(`${this.subdomain}/academy/quiz`, data);
  }
  /**
   * Answer quiz
   * @param {number} {Required} surveyId
   * @param {number} {Required} questionId
   * @param {number} {Required} optionId
   * @param {number} {Required} surveyTakenId
   * @returns {Promise}
   */
  answerQuiz(data) {
    return HttpService.post(`${this.subdomain}/academy/quiz/answer`, data);
  }
  /**
   * Finish quiz
   * @param {number} {Required} surveyId
   * @param {number} {Required} surveyTakenId
   * @returns {Promise}
   */
  finishQuiz(data) {
    return HttpService.post(`${this.subdomain}/academy/quiz/finish`, data);
  }
  /**
   * Finally quiz
   * @param {number} {Required} surveyId
   * @param {number} {Required} surveyTakenId
   * @returns {Promise}
   */
  checkFinalQuiz(contentTypeId) {
    return HttpService.get(`/academy/finalcheck?ContentTypeId=${contentTypeId}`);
  }
}

export default new Entrepreneurship();
