import { HttpService } from '@/services/HttpService';

class Content {
  subdomain = '/content';
  /**
   * Get plain package data
   * @returns {Promise}
   */
  getPlainPackage() {
    return HttpService.get(`${this.subdomain}/plainpackage`);
  }

  getBrandHistory(brandName) {
    return HttpService.post(`${this.subdomain}/BrandHistory`, brandName);
  }

  setVideoLog(contentId, viewStatus, percentage, position, isStarting) {
    const log = {
      videoId: contentId,
      viewStatus: viewStatus,
      percentWatched: percentage,
      playbackPosition: position,
      isStarting: isStarting,
    };
    if (log.percentage > 1) {
      log.percentage = 1;
    }
    if (log.percentage < 0) {
      log.percentage = 0;
    }
    return HttpService.put(`${this.subdomain}/videolog`, log);
  }

  /**
   * Get feed by feed id
   * @param {number} {Required} feedId
   * @returns {Promise}
   */
  getFeedContent(feedId, IsFromFeed) {
    return HttpService.get(`${this.subdomain}/${feedId}/${IsFromFeed}`);
  }
  getFeedFilters() {
    return HttpService.get(`${this.subdomain}/feedfilters`);
  }
  /**
   * Post like action to content
   * @param {number} {Required} feedId
   * @returns {Promise}
   */
  likeContent(feedId) {
    return HttpService.put(`${this.subdomain}/like`, {
      ...feedId,
    });
  }
  /**
   * Get content brand ID
   * @returns {Promise}
   */
  getBrandNews(lastId, lastIndex, pageSize, brandId) {
    let queryString = `?LastId=${lastId}&StartIndex=${lastIndex}&PageSize=${pageSize}&brandId=${brandId}`;
    return HttpService.get(`${this.subdomain}${queryString}`);
  }
  getPladisBrandNews(lastId, lastIndex, pageSize, contentTypeId) {
    let queryString = `?LastId=${lastId}&StartIndex=${lastIndex}&PageSize=${pageSize}&contentTypeId=${contentTypeId}`;
    return HttpService.get(`${this.subdomain}${queryString}`);
  }
  /**
   * Put feedback type
   * @param {number} {Required} contentId
   * @param {number} {Required} feedbackPoint
   * @returns {Promise}
   */
  feedBack(data) {
    return HttpService.put(`${this.subdomain}/feedback`, {
      ...data,
    });
  }

  getUserGuide(isFromRosette) {
    return HttpService.get(`${this.subdomain}/userguide?isFromRosette=${isFromRosette}`);
  }
}
export default new Content();
