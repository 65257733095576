import { HttpService } from '@/services/HttpService';
class Rosettes {
  subdomain = '/rosette';

  getRosetteDetail(rosetteId) {
    return HttpService.get(`${this.subdomain}/${rosetteId}`);
  }

  getRosetteQuiz(surveyId) {
    return HttpService.get(`${this.subdomain}/quiz/${surveyId}`);
  }

  getRosetteQuizAnswer(request) {
    return HttpService.post(`${this.subdomain}/quiz/answer`, request);
  }

  getRosetteQuizFinish(request) {
    return HttpService.post(`${this.subdomain}/quiz/finish`, request);
  }
}

export default new Rosettes();
